/**
 * Returns the name of the month (not just anumber);
 *
 * @param {string} date
 * @returns {string}
 */
export const getMonth = (date) =>
  new Date(date).toLocaleDateString("en-US", { month: "short" })

/**
 * Returns the day
 *
 * @param {string} date
 * @returns {string}
 */
export const getDate = (date) => new Date(date).getDate()
