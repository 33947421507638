import React from "react";
import { Link } from "gatsby";
import ModalVideo from "react-modal-video";

const VideoArea = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  return (
    <section className="video-area video-bg">
      <div className="diplay-table">
        <div className="display-table-cell">
          <div className="video-inner-content">
            <div className="button__holder">
              <ModalVideo
                channel="youtube"
                isOpen={isOpen}
                videoId="lx1jBht46vw"
                onClose={() => setIsOpen(false)}
              />
              <Link
                to="#"
                className="plus popup-youtube"
                title="Dhamira interviewed by Fauna"
                onClick={(e) => {
                  e.preventDefault();
                  openModal();
                }}
              ></Link>
            </div>
            <h2>Watch Video Spotlight</h2>
            <p>
              One of our favorite infrastructre providers is Fauna.com, and they
              recently interviewed us to see how we leverage their technology to
              serve our clients. Take a look!
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VideoArea;
