import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";

import BlogItem from "../BlogItem";

const getRecentBlogs = graphql`
  {
    blogs: allMarkdownRemark(
      limit: 4
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            date
            description
            image
            tags
          }
        }
      }
    }
  }
`;

const Blog = () => {
  const data = useStaticQuery(getRecentBlogs);
  const blogs = data.blogs.edges;

  return (
    <section id="blog" className="our-blog ptb-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2 text-center">
            <div className="section-title">
              <h2>Our Blog</h2>
              <p>
                Sharing our thoughts on software development, principles and
                practices, and productivity.
              </p>
              <span className="section-title-bg">Blog</span>
            </div>
          </div>
        </div>

        <div className="row">
          {blogs.map(({ node: blog }) => (
            <div className="col-md-6 col-lg-6" key={blog.id}>
              <BlogItem blog={blog} />
            </div>
          ))}

          <div className="col-lg-12 col-md-12 all-post">
            <div className="center-wrap">
              <Link to="/blog" className="btn-a">
                <div className="button">
                  All Articles <i className="fa fa-arrow-right"></i>
                  <div className="mask"></div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Blog;
