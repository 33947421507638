import React from 'react';
import ReactMarkdown from 'react-markdown';

import pageData from 'content/about.json'

const About = () => {
    return (
        <div id="about" className="about-us">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2 text-center">
                        <div className="section-title text-center">
                            <h2>{pageData.topTitle}</h2>
                            <p>{pageData.description}</p>
                            <span className="section-title-bg">About</span>
                        </div>
                    </div>
                </div>

                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="about-text">
                            <h3>{pageData.title}</h3>
                            <ReactMarkdown>{pageData.content}</ReactMarkdown>
                            {/* <div dangerouslySetInnerHTML={{ __html: pageData.content }}></div>  */}
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <img src={pageData.image} style={{width: '100%'}} alt="about-img" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About;