import React from 'react'
import Loadable from '@loadable/component'

import pageData from 'content/testimonials.json'

const OwlCarousel = Loadable(() => import('react-owl-carousel3'))

const Testimonials = () => {
    const [display, setDisplay] = React.useState(false)

    React.useEffect(() => {
        setDisplay(true)
    }, [])

    const testimonialsitem = pageData.testimonials.map((testimonial, index) => (
        <div className="single-testimonial-item text-center" key={index}>
            <i className="fa fa-quote-left"></i>
            <p>{testimonial.content}</p>

            <div className="client-profile">
                <img src={testimonial.clientImage} alt="client-one" />
            </div>
            
            <div className="client-info">
                <h3>{testimonial.name}</h3>
                <span>{testimonial.profession}</span>
            </div>
        </div>
    ))

    return (
        <div className="testimonials ptb-100">
            <div className="container">
                { display ? <OwlCarousel
                    className="owl-theme testimonial-slides"
                    items={1}
                    nav= {true}
                    dots= {false}
                    autoplay= {false}
                    loop= {true}
                    autoplayHoverPause= {true}
                    smartSpeed= {1000}
                    navText= {[
                        "<i class='fa fa-chevron-left'></i>", 
                        "<i class='fa fa-chevron-right'></i>"
                    ]}
                >
                    {testimonialsitem}
                </OwlCarousel> : '' }
            </div>
        </div>
    )
}

export default Testimonials
